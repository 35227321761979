import { Button } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import { ExtraContentWrapper } from './style';

const ExtraContent: FC = () => (
  <ExtraContentWrapper className="wrapper">
    <Link to="/application-form-for-veterinarians">
      <Button type="primary">REGISTER HERE</Button>
    </Link>
    <Link className="customize-link" to="/terms-and-conditions-for-veterinarians">
      Here are the Terms and Conditions for Veterinarians
    </Link>
  </ExtraContentWrapper>
);

export default ExtraContent;
