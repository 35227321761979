import styled from 'styled-components';

export const ExtraContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background-color: rgba(48, 185, 127, 1);
    color: #ffffff;
    text-decoration: none;
    border-color: transparent;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s linear;
  }

  button:hover {
    background-color: rgba(82, 142, 255, 1);
  }

  a.customize-link {
    padding-top: 30px;
    font-size: 17px;
    color:  rgba(82, 142, 255, 1);
  }
`;
